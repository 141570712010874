import './bootstrap';

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

import PerfectScrollbar from 'perfect-scrollbar';

windows.PerfectScrollbar = PerfectScrollbar;

PerfectScrollbar.start();